<template>
    <section>
        <h3>
            <Icon v="fist-raised" />
            <T>home.mission.header</T>
        </h3>

        <p class="h5">
            <T>home.mission.summary</T>
        </p>

        <ul>
            <li><T>home.mission.freedom</T></li>
            <li><T>home.mission.respect</T></li>
            <li><T>home.mission.inclusivity</T></li>
        </ul>
    </section>
</template>
